+ function($) {
	'use strict';
	
	/* Hero slider */
    var heroSlider = $('.hero-slider');
	heroSlider.on('init', function(e, slick) {
        var $firstAnimatingElements = $('div.hero-item:first-child').find('[data-animation]');
        doAnimations($firstAnimatingElements);    
    });
    heroSlider.on('beforeChange', function(e, slick, currentSlide, nextSlide) {
        var $animatingElements = $('div.hero-item[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
        doAnimations($animatingElements);    
    });
    heroSlider.slick({
		dots: false,
		infinite: true,
		speed: 500,
		fade: !0,
		cssEase: 'linear',
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		autoplaySpeed: 5000,
		arrows: true,
		prevArrow:'<img src="assets/images/prev.svg" class="animated fadeInLeft slick-arrow slick-prev" width="50">',
        nextArrow:'<img src="assets/images/next.svg" class="animated fadeInRight slick-arrow slick-next" width="50">',
        responsive: [{
            breakpoint: 1200,
            settings: {
                arrows: false
            }
        }],
	});
	
	//Slick slider animations
    function doAnimations(elements) {
        var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
        elements.each(function() {
            var $this = $(this);
            var $animationDelay = $this.data('delay');
            var $animationType = 'animated ' + $this.data('animation');
            $this.css({
                'animation-delay': $animationDelay,
                '-webkit-animation-delay': $animationDelay
            });
            $this.addClass($animationType).one(animationEndEvents, function() {
                $this.removeClass($animationType);
            });
        });
    }
	
	// Load backgorund image from data attribute
	loadBackground();
	$(window).on('resize orientationchange', loadBackground);
	function loadBackground(){
		if ($('[data-background]').length > 0) {
			$('[data-background]').each(function() {
				var $background, $backgroundmobile, $this;
				$this = $(this);
				$background = $this.attr('data-background');
				$backgroundmobile = $this.attr('data-background-mobile');
				if ($this.attr('data-background').substr(0, 1) === '#') {
					return $this.css('background-color', $background);
				} else if ($this.attr('data-background-mobile') && device.mobile()) {
					return $this.css('background-image', 'url(' + $backgroundmobile + ')');
				} else {
					return $this.css('background-image', 'url(' + $background + ')');
				}
			});
		}
	}
	
	// Counter
	$('.counter').counterUp({
        delay: 10,
        time: 1000
    });
	
	//Scroll to Top
	$(window).scroll(function () {
		if ($(this).scrollTop() >= 100) {
			$('.scroll-to-top').fadeIn(200);
		} else {
			$('.scroll-to-top').fadeOut(200);
		}
	});
	$('.scroll-to-top').click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
	});
	
	// Get current URL path and assign 'active' class
	var url = window.location;
	$('.nav > li > a[href="'+url+'"]').parent().addClass('active');
	$('.nav a').filter(function () {
		return this.href == url;
	}).parent().addClass('active').parent().parent().addClass('active');

	/* Contact form */
	$('#form-contact').validate({
		rules: {
			'fc-name': {
				required: true
			},
			'fc-email': {
				required: true,
				email: true
			},
			'fc-phone': {
				required: true,
				digits: true
			},
			'fc-subject':{
				required: true
			}
		},
		highlight: function(element) {
			$(element).parent().is('.has-success, .has-error') ? 
			$(element).parent().removeClass('has-success').addClass('has-error') : 
			$(element).wrap('<span class="has-error"></span>');
		},
		unhighlight: function(element) {
			$(element).parent().is('.has-success, .has-error') ? 
			$(element).parent().removeClass('has-error').addClass('has-success') : 
			$(element).wrap('<span class="has-success"></span>');
		},
		errorElement: 'span',
		errorClass: 'help-block',
		errorPlacement: function(error, element) {},
		submitHandler: function(form) {
			var submitBtn = $(form).find("button[type=submit]");
			var submitBtnText = submitBtn.html();
			submitBtn.html("Please wait...").attr('disabled', true);
			$.ajax({
				url: form.action,
				type: form.method,
				dataType: "json",
				data: $(form).serialize(),
				success: function(response) {
					submitBtn.html(submitBtnText).attr('disabled', false);
					$('#result-contact').html('<div class="alert alert-'+response.type+'"><a href="#" class="close" data-dismiss="alert" aria-label="close" title="close">×</a>'+response.message+'</div>');
					if(response.type == "success"){
						$(form).find('.has-success').removeClass('has-success');
						$(form)[0].reset();
					}
				}            
			});
		}
	});
	
}(jQuery);